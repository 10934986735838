<template>
  <Modal
    :isConfirmDisabled="false"
    :show="show"
    @confirm="handleConfirm"
    :confirmationText="confirmationText"
    @cancel="handleCancel"
    cancelText="Cancel"
    :cancel="false"
    :width="width"
  >
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h2 class="font-bold mb-0">{{ title }}</h2>
        </div>
        <img
          src="@/assets/icons/close.svg"
          alt="Info"
          class="h-3 w-3 cursor-pointer"
          @click="handleCancel"
        />
      </div>
      <div class="m-auto border-b"></div>
      <h2 class="font-bold my-4">{{ subtitle }}</h2>
    </template>
    <template v-slot:body>
      <div v-if="isHtml" v-html="content"></div>
      <h2 v-else="my-4">
        {{ content }}
      </h2>
    </template>
  </Modal>
</template>

<script setup>
import Modal from "@/components/general/ConfirmationModal.vue";

const props = defineProps({
  show: Boolean,
  title: String,
  subtitle: String,
  content: String,
  width: {
    type: String,
    default: "500px",
  },
  confirmationText: {
    type: String,
    default: "Close",
  },
  isHtml: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:show", "confirm"]);

function handleConfirm() {
  emit("confirm");
}

function handleCancel() {
  emit("update:show", false);
}
</script>
